import React, { FunctionComponent } from 'react';
import styles from './video-feature-wrapper.module.scss';
import {
  VideoFeatureBox,
  IVideoFeatureProps
} from '../video-feature-box/video-feature-box';

interface IVideoFeatureWrapperProps {
  components: IVideoFeatureProps[];
}

export const VideoFeatureWrapper: FunctionComponent<IVideoFeatureWrapperProps> = ({
  components
}) => {
  return (
    <div className={styles.videoFeatureWrapper}>
      {components.map((component: IVideoFeatureProps) => (
        <VideoFeatureBox key={component.id} data={component.data} />
      ))}
    </div>
  );
};

import React, { FunctionComponent } from 'react';
import styles from './video-feature-box.module.scss';

export interface IVideoFeatureProps {
  id: string;
  data: {
    videoUrl: string;
    ico: string;
    headline: string;
    content: string;
  };
}

export const VideoFeatureBox: FunctionComponent<IVideoFeatureProps> = ({
  data
}) => {
  const { videoUrl, ico, headline, content } = data;
  return (
    <article className={styles.videoBoxWrapper}>
      <div className={styles.videoBoxContent}>
        <div className={styles.videoBoxHeader}>
          {ico.length !== 0 && <i className={'material-icons'}>{ico}</i>}
          <h3 className={styles.videoBoxHeadline}>{headline}</h3>
        </div>
        <div
          className={styles.videoBoxText}
          dangerouslySetInnerHTML={{ __html: content }}
        ></div>
      </div>
      <video
        className={styles.videoBoxVideo}
        autoPlay
        loop
        muted
        src={videoUrl}
      ></video>
    </article>
  );
};

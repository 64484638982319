export const navbarMock = [
  {
    name: 'Solutions',
    isActive: false,
    children: [
      {
        name: 'Enterprise',
        url: '/enterprise', // e.g. "/startup"
        isActive: false
      },
      {
        name: 'Enterprise',
        url: '/enterprise', // e.g. "/startup"
        isActive: false
      },
      {
        name: 'Enterprise',
        url: '/enterprise', // e.g. "/startup"
        isActive: false
      }
    ]
  },
  {
    name: 'Case Studies',
    isActive: false,
    url: '/enterprise'
  },
  {
    name: 'Team',
    isActive: false,
    url: '/enterprise'
  },
  {
    name: 'Career',
    isActive: false,
    children: [
      {
        name: 'Enterprise',
        url: '/enterprise', // e.g. "/startup"
        isActive: false
      },
      {
        name: 'Enterprise',
        url: '/enterprise', // e.g. "/startup"
        isActive: false
      },
      {
        name: 'Enterprise',
        url: '/enterprise', // e.g. "/startup"
        isActive: false
      }
    ]
  },
  {
    name: 'Community',
    isActive: false,
    children: [
      {
        name: 'Enterprise',
        url: '/enterprise', // e.g. "/startup"
        isActive: false
      },
      {
        name: 'Enterprise',
        url: '/enterprise', // e.g. "/startup"
        isActive: false
      },
      {
        name: 'Enterprise',
        url: '/enterprise', // e.g. "/startup"
        isActive: false
      }
    ]
  },
  {
    name: 'Blog',
    isActive: false,
    url: '/enterprise'
  },
  {
    name: 'Estimate project',
    isActive: false,
    url: '/enterprise',
    extraClass: 'button'
  }
];

export const videoFeatureMock = [
  {
    id: '8736198456',
    data: {
      videoUrl:
        'https://assets-eu-01.kc-usercontent.com/665ad26e-74b6-0197-a66f-4ff80d2ded6b/3d608466-3434-4b5d-93a0-6ca578936ed9/video-feature-box.mp4',
      ico: 'android',
      headline: 'Maecenas auctor rutrum luctus.',
      content:
        '<p>Sed ut <strong>perspiciatis unde omnis</strong> iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab</p>'
    }
  },
  {
    id: '3154810201',
    data: {
      videoUrl:
        'https://assets-eu-01.kc-usercontent.com/665ad26e-74b6-0197-a66f-4ff80d2ded6b/3d608466-3434-4b5d-93a0-6ca578936ed9/video-feature-box.mp4',
      ico: 'accessibility',
      headline: 'Etiam hendrerit venenatis nulla id elementum.',
      content:
        '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>'
    }
  }
];

import React, { useContext, FunctionComponent } from 'react';
import styles from './debug-toolbar.module.scss';
import { AppStore, AppActionType } from '../../store/app.context';

export const DebugToolbar: FunctionComponent<{}> = () => {
  const { dispatch } = useContext(AppStore);

  const toggleGrid = (): void => {
    dispatch({ type: AppActionType.toggleGridHelper });
  };

  return (
    <div className={styles.toolbar}>
      <div className={styles.hoverElement}>Toolbar</div>
      <button className={styles.triggerGridHelper} onClick={toggleGrid}>
        Trigger Grid
      </button>
    </div>
  );
};

import { ThemeVariantType } from '../typings';

export const carouselMock1 = {
  imageCollection: [
    'https://softwarehut.b-cdn.net/wp-content/uploads/2019/12/react.svg',
    'https://softwarehut.b-cdn.net/wp-content/uploads/2019/12/angular.svg',
    'https://softwarehut.b-cdn.net/wp-content/uploads/2019/12/java.svg',
    'https://softwarehut.b-cdn.net/wp-content/uploads/2019/12/react-native.svg',
    'https://softwarehut.b-cdn.net/wp-content/uploads/2019/12/node.svg',
    'https://softwarehut.b-cdn.net/wp-content/uploads/2019/12/vue.svg',
    'https://softwarehut.b-cdn.net/wp-content/uploads/2019/12/angular.svg',
    'https://softwarehut.b-cdn.net/wp-content/uploads/2019/12/dotnet.svg',
    'https://softwarehut.b-cdn.net/wp-content/uploads/2019/12/ios.svg',
    'https://softwarehut.b-cdn.net/wp-content/uploads/2019/12/android.svg',
    'https://softwarehut.b-cdn.net/wp-content/uploads/2019/12/react-native.svg'
  ],
  headline: '<p>Our&nbsp;<strong>tech stack</strong></p>',
  themeVariant: 'secondary' as ThemeVariantType,
  withArrow: true,
  allowAutoplay: true,
  slidesPerPage: 3
};

export const carouselMock2 = {
  imageCollection: [
    'https://softwarehut.b-cdn.net/wp-content/uploads/2016/08/sitecore_logo.png',
    'https://softwarehut.b-cdn.net/wp-content/uploads/2019/12/microsoft-1.png',
    'https://softwarehut.b-cdn.net/wp-content/uploads/2020/01/certificate-istqb.png',
    'https://softwarehut.b-cdn.net/wp-content/uploads/2020/01/certificate-prince2.png'
  ],
  headline: '<p>Our&nbsp;<strong>tech stack</strong></p>',
  themeVariant: 'primary' as ThemeVariantType,
  withArrow: false,
  allowAutoplay: true,
  slidesPerPage: 5
};

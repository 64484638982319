import { CircleFeatureCard } from '../components/circle-feature-card/circle-feature-card';
import { Navbar } from '../components/navbar/navbar';
import { Page } from '../components/page/page.component';
import React, { FunctionComponent } from 'react';
import '../styles/global.css';
import '../styles/reset.css';
import '../styles/global.css';
import {
  navbarMock,
  videoFeatureMock,
  personBoxMock,
  contactMock,
  carouselMock1,
  carouselMock2
} from '../mocks';
import { EmployeeCollection } from '../components/employee-collection/employee-collection';
import { FourColumnGrid } from '../components/four-column-grid/four-column-grid';
import { ContactBox } from '../components/contact-box/contact-box';
import { VideoFeatureWrapper } from '../components/video-feature-wrapper/video-feature-wrapper';
import { CarouselComponent } from '../components/carousel/carousel';
import { TextBlockSchema } from '../components/text-block-schema/text-block-schema';
import { QuoteWithImage } from '../components/quote-with-image/quote-with-image';

const Demo: FunctionComponent<{}> = props => {
  return (
    <Page>
      <Navbar links={navbarMock} />
      <VideoFeatureWrapper components={videoFeatureMock} />
      <CircleFeatureCard
        data={{
          ico: 'face',
          content: '<h2>Twoj naglowek bedzie</h2><p>tutaj</p>'
        }}
        isVertical={false}
      />
      <CircleFeatureCard
        data={{
          ico: 'assignment_turned_in',
          content: '<h2>Naglowek jakis</h2><p>Bedzie dluzszy tekst a co</p>'
        }}
        isVertical={true}
      />
      <FourColumnGrid>
        <EmployeeCollection employeeCollection={personBoxMock} />
        <ContactBox {...contactMock} />
        <EmployeeCollection employeeCollection={personBoxMock} />
      </FourColumnGrid>
      <CarouselComponent {...carouselMock1} />
      <CarouselComponent {...carouselMock2} />

      {/* <CaseStudyHero
        backgroundImage={elements.background_image}
        headline={elements.headline}
        description={elements.description}
        award={elements.award_image}
        summary={elements.summary}
      /> */}
      {/* <BlogArticlesList
        articles={blogArticles}
        buttonText="Load More"
        title="Title"
        subtitle="subtitle"
      /> */}
      <TextBlockSchema
        text="Extraction of localisable resources from source code"
        hasArrow={true}
      />
      <TextBlockSchema
        text="Creation of a central repository for localisable data from various sources"
        hasArrow={true}
      />
      <TextBlockSchema
        text="Development of automatic text localisation process"
        hasArrow={true}
      />
      <TextBlockSchema
        text="Establishing cooperation with translators and translation agencies"
        hasArrow={false}
      />
      <br />
      <br />
      <QuoteWithImage
        oneColorBackground={true}
        quote="Our Polish branch employees, who later formed Order of Code, provided us with invaluable support in terms of software localisation and testing. It was primarily their experience but also creative thinking and understanding of business needs that contributed to excellent results. Our localisation became professional, translation process automated and integrated into our software development process. I also have to point out that having foreigners working on the localisation of an English website was a very good decision because their thinking was not “English-centric” and it influenced many business areas, helping us to provide a superior quality of service in foreign markets and grow our business there quickly."
        author="Gareth Williams"
        authorPosition="CEO Skyscanner Ltd"
      />
    </Page>
  );
};

export default Demo;

export const personBoxMock = [
  {
    firstName: 'Robert',
    lastName: 'Strzelecki',
    position: 'Chief Execiutive Officer',
    photoUrl:
      'https://softwarehut.com/wp-content/uploads/2016/10/robert_strzelecki.jpg',
    contact: [
      {
        icon: 'e-mail',
        url: 'mailto:robert.strzelecki@softwarehut.com' // e.g. "mailto:xxx" lub "skype:example123?chat"
      },
      {
        icon: 'facebook',
        url: 'mailto:robert.strzelecki@softwarehut.com' // e.g. "mailto:xxx" lub "skype:example123?chat"
      },
      {
        icon: 'linkedin',
        url: 'mailto:robert.strzelecki@softwarehut.com' // e.g. "mailto:xxx" lub "skype:example123?chat"
      },
      {
        icon: 'skype',
        url: 'mailto:robert.strzelecki@softwarehut.com' // e.g. "mailto:xxx" lub "skype:example123?chat"
      },
      {
        icon: 'upwork',
        url: 'mailto:robert.strzelecki@softwarehut.com' // e.g. "mailto:xxx" lub "skype:example123?chat"
      }
    ]
  },
  {
    firstName: 'Robert',
    lastName: 'Strzelecki',
    position: 'Chief Execiutive Officer',
    photoUrl:
      'https://softwarehut.com/wp-content/uploads/2016/10/robert_strzelecki.jpg',
    contact: []
  },
  {
    firstName: 'Robert',
    lastName: 'Strzelecki',
    position: 'Chief Execiutive Officer',
    photoUrl:
      'https://softwarehut.com/wp-content/uploads/2016/10/robert_strzelecki.jpg',
    contact: [
      {
        icon: 'e-mail',
        url: 'mailto:robert.strzelecki@softwarehut.com' // e.g. "mailto:xxx" lub "skype:example123?chat"
      },
      {
        icon: 'facebook',
        url: 'mailto:robert.strzelecki@softwarehut.com' // e.g. "mailto:xxx" lub "skype:example123?chat"
      },
      {
        icon: 'skype',
        url: 'mailto:robert.strzelecki@softwarehut.com' // e.g. "mailto:xxx" lub "skype:example123?chat"
      }
    ]
  },
  {
    firstName: 'Robert',
    lastName: 'Strzelecki',
    position: 'Chief Execiutive Officer',
    photoUrl:
      'https://softwarehut.com/wp-content/uploads/2016/10/robert_strzelecki.jpg',
    contact: [
      {
        icon: 'e-mail',
        url: 'mailto:robert.strzelecki@softwarehut.com' // e.g. "mailto:xxx" lub "skype:example123?chat"
      },
      {
        icon: 'facebook',
        url: 'mailto:robert.strzelecki@softwarehut.com' // e.g. "mailto:xxx" lub "skype:example123?chat"
      },
      {
        icon: 'skype',
        url: 'mailto:robert.strzelecki@softwarehut.com' // e.g. "mailto:xxx" lub "skype:example123?chat"
      }
    ]
  },
  {
    firstName: 'Robert',
    lastName: 'Strzelecki',
    position: 'Chief Execiutive Officer',
    photoUrl:
      'https://softwarehut.com/wp-content/uploads/2016/10/robert_strzelecki.jpg',
    contact: [
      {
        icon: 'e-mail',
        url: 'mailto:robert.strzelecki@softwarehut.com' // e.g. "mailto:xxx" lub "skype:example123?chat"
      },
      {
        icon: 'facebook',
        url: 'mailto:robert.strzelecki@softwarehut.com' // e.g. "mailto:xxx" lub "skype:example123?chat"
      },
      {
        icon: 'skype',
        url: 'mailto:robert.strzelecki@softwarehut.com' // e.g. "mailto:xxx" lub "skype:example123?chat"
      }
    ]
  },
  {
    firstName: 'Robert',
    lastName: 'Strzelecki',
    position: 'Chief Execiutive Officer',
    photoUrl:
      'https://softwarehut.com/wp-content/uploads/2016/10/robert_strzelecki.jpg',
    contact: [
      {
        icon: 'e-mail',
        url: 'mailto:robert.strzelecki@softwarehut.com' // e.g. "mailto:xxx" lub "skype:example123?chat"
      },
      {
        icon: 'facebook',
        url: 'mailto:robert.strzelecki@softwarehut.com' // e.g. "mailto:xxx" lub "skype:example123?chat"
      },
      {
        icon: 'skype',
        url: 'mailto:robert.strzelecki@softwarehut.com' // e.g. "mailto:xxx" lub "skype:example123?chat"
      }
    ]
  },
  {
    firstName: 'Robert',
    lastName: 'Strzelecki',
    position: 'Chief Execiutive Officer',
    photoUrl:
      'https://softwarehut.com/wp-content/uploads/2016/10/robert_strzelecki.jpg',
    contact: [
      {
        icon: 'e-mail',
        url: 'mailto:robert.strzelecki@softwarehut.com' // e.g. "mailto:xxx" lub "skype:example123?chat"
      },
      {
        icon: 'facebook',
        url: 'mailto:robert.strzelecki@softwarehut.com' // e.g. "mailto:xxx" lub "skype:example123?chat"
      },
      {
        icon: 'skype',
        url: 'mailto:robert.strzelecki@softwarehut.com' // e.g. "mailto:xxx" lub "skype:example123?chat"
      }
    ]
  },
  {
    firstName: 'Robert',
    lastName: 'Strzelecki',
    position: 'Chief Execiutive Officer',
    photoUrl:
      'https://softwarehut.com/wp-content/uploads/2016/10/robert_strzelecki.jpg',
    contact: [
      {
        icon: 'e-mail',
        url: 'mailto:robert.strzelecki@softwarehut.com' // e.g. "mailto:xxx" lub "skype:example123?chat"
      },
      {
        icon: 'facebook',
        url: 'mailto:robert.strzelecki@softwarehut.com' // e.g. "mailto:xxx" lub "skype:example123?chat"
      },
      {
        icon: 'skype',
        url: 'mailto:robert.strzelecki@softwarehut.com' // e.g. "mailto:xxx" lub "skype:example123?chat"
      }
    ]
  },
  {
    firstName: 'Robert',
    lastName: 'Strzelecki',
    position: 'Chief Execiutive Officer',
    photoUrl:
      'https://softwarehut.com/wp-content/uploads/2016/10/robert_strzelecki.jpg',
    contact: [
      {
        icon: 'e-mail',
        url: 'mailto:robert.strzelecki@softwarehut.com' // e.g. "mailto:xxx" lub "skype:example123?chat"
      },
      {
        icon: 'facebook',
        url: 'mailto:robert.strzelecki@softwarehut.com' // e.g. "mailto:xxx" lub "skype:example123?chat"
      },
      {
        icon: 'skype',
        url: 'mailto:robert.strzelecki@softwarehut.com' // e.g. "mailto:xxx" lub "skype:example123?chat"
      }
    ]
  },
  {
    firstName: 'Robert',
    lastName: 'Strzelecki',
    position: 'Chief Execiutive Officer',
    photoUrl:
      'https://softwarehut.com/wp-content/uploads/2016/10/robert_strzelecki.jpg',
    contact: [
      {
        icon: 'e-mail',
        url: 'mailto:robert.strzelecki@softwarehut.com' // e.g. "mailto:xxx" lub "skype:example123?chat"
      },
      {
        icon: 'facebook',
        url: 'mailto:robert.strzelecki@softwarehut.com' // e.g. "mailto:xxx" lub "skype:example123?chat"
      },
      {
        icon: 'skype',
        url: 'mailto:robert.strzelecki@softwarehut.com' // e.g. "mailto:xxx" lub "skype:example123?chat"
      }
    ]
  },
  {
    firstName: 'Robert',
    lastName: 'Strzelecki',
    position: 'Chief Execiutive Officer',
    photoUrl:
      'https://softwarehut.com/wp-content/uploads/2016/10/robert_strzelecki.jpg',
    contact: [
      {
        icon: 'e-mail',
        url: 'mailto:robert.strzelecki@softwarehut.com' // e.g. "mailto:xxx" lub "skype:example123?chat"
      },
      {
        icon: 'facebook',
        url: 'mailto:robert.strzelecki@softwarehut.com' // e.g. "mailto:xxx" lub "skype:example123?chat"
      },
      {
        icon: 'skype',
        url: 'mailto:robert.strzelecki@softwarehut.com' // e.g. "mailto:xxx" lub "skype:example123?chat"
      }
    ]
  },
  {
    firstName: 'Robert',
    lastName: 'Strzelecki',
    position: 'Chief Execiutive Officer',
    photoUrl:
      'https://softwarehut.com/wp-content/uploads/2016/10/robert_strzelecki.jpg',
    contact: [
      {
        icon: 'e-mail',
        url: 'mailto:robert.strzelecki@softwarehut.com' // e.g. "mailto:xxx" lub "skype:example123?chat"
      },
      {
        icon: 'facebook',
        url: 'mailto:robert.strzelecki@softwarehut.com' // e.g. "mailto:xxx" lub "skype:example123?chat"
      },
      {
        icon: 'skype',
        url: 'mailto:robert.strzelecki@softwarehut.com' // e.g. "mailto:xxx" lub "skype:example123?chat"
      }
    ]
  },
  {
    firstName: 'Robert',
    lastName: 'Strzelecki',
    position: 'Chief Execiutive Officer',
    photoUrl:
      'https://softwarehut.com/wp-content/uploads/2016/10/robert_strzelecki.jpg',
    contact: [
      {
        icon: 'e-mail',
        url: 'mailto:robert.strzelecki@softwarehut.com' // e.g. "mailto:xxx" lub "skype:example123?chat"
      },
      {
        icon: 'facebook',
        url: 'mailto:robert.strzelecki@softwarehut.com' // e.g. "mailto:xxx" lub "skype:example123?chat"
      },
      {
        icon: 'skype',
        url: 'mailto:robert.strzelecki@softwarehut.com' // e.g. "mailto:xxx" lub "skype:example123?chat"
      }
    ]
  },
  {
    firstName: 'Robert',
    lastName: 'Strzelecki',
    position: 'Chief Execiutive Officer',
    photoUrl:
      'https://softwarehut.com/wp-content/uploads/2016/10/robert_strzelecki.jpg',
    contact: [
      {
        icon: 'e-mail',
        url: 'mailto:robert.strzelecki@softwarehut.com' // e.g. "mailto:xxx" lub "skype:example123?chat"
      },
      {
        icon: 'facebook',
        url: 'mailto:robert.strzelecki@softwarehut.com' // e.g. "mailto:xxx" lub "skype:example123?chat"
      },
      {
        icon: 'skype',
        url: 'mailto:robert.strzelecki@softwarehut.com' // e.g. "mailto:xxx" lub "skype:example123?chat"
      }
    ]
  },
  {
    firstName: 'Robert',
    lastName: 'Strzelecki',
    position: 'Chief Execiutive Officer',
    photoUrl:
      'https://softwarehut.com/wp-content/uploads/2016/10/robert_strzelecki.jpg',
    contact: [
      {
        icon: 'e-mail',
        url: 'mailto:robert.strzelecki@softwarehut.com' // e.g. "mailto:xxx" lub "skype:example123?chat"
      },
      {
        icon: 'facebook',
        url: 'mailto:robert.strzelecki@softwarehut.com' // e.g. "mailto:xxx" lub "skype:example123?chat"
      },
      {
        icon: 'skype',
        url: 'mailto:robert.strzelecki@softwarehut.com' // e.g. "mailto:xxx" lub "skype:example123?chat"
      }
    ]
  },
  {
    firstName: 'Robert',
    lastName: 'Strzelecki',
    position: 'Chief Execiutive Officer',
    photoUrl:
      'https://softwarehut.com/wp-content/uploads/2016/10/robert_strzelecki.jpg',
    contact: [
      {
        icon: 'e-mail',
        url: 'mailto:robert.strzelecki@softwarehut.com' // e.g. "mailto:xxx" lub "skype:example123?chat"
      },
      {
        icon: 'facebook',
        url: 'mailto:robert.strzelecki@softwarehut.com' // e.g. "mailto:xxx" lub "skype:example123?chat"
      },
      {
        icon: 'skype',
        url: 'mailto:robert.strzelecki@softwarehut.com' // e.g. "mailto:xxx" lub "skype:example123?chat"
      }
    ]
  },
  {
    firstName: 'Robert',
    lastName: 'Strzelecki',
    position: 'Chief Execiutive Officer',
    photoUrl:
      'https://softwarehut.com/wp-content/uploads/2016/10/robert_strzelecki.jpg',
    contact: [
      {
        icon: 'e-mail',
        url: 'mailto:robert.strzelecki@softwarehut.com' // e.g. "mailto:xxx" lub "skype:example123?chat"
      },
      {
        icon: 'facebook',
        url: 'mailto:robert.strzelecki@softwarehut.com' // e.g. "mailto:xxx" lub "skype:example123?chat"
      },
      {
        icon: 'skype',
        url: 'mailto:robert.strzelecki@softwarehut.com' // e.g. "mailto:xxx" lub "skype:example123?chat"
      }
    ]
  },
  {
    firstName: 'Robert',
    lastName: 'Strzelecki',
    position: 'Chief Execiutive Officer',
    photoUrl:
      'https://softwarehut.com/wp-content/uploads/2016/10/robert_strzelecki.jpg',
    contact: [
      {
        icon: 'e-mail',
        url: 'mailto:robert.strzelecki@softwarehut.com' // e.g. "mailto:xxx" lub "skype:example123?chat"
      },
      {
        icon: 'facebook',
        url: 'mailto:robert.strzelecki@softwarehut.com' // e.g. "mailto:xxx" lub "skype:example123?chat"
      },
      {
        icon: 'skype',
        url: 'mailto:robert.strzelecki@softwarehut.com' // e.g. "mailto:xxx" lub "skype:example123?chat"
      }
    ]
  }
];

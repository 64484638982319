import React, { useContext, useEffect } from 'react';
import { withAppStore, AppStore, AppActionType } from '../../store/app.context';
import { DebugToolbar } from '../debug-toolbar/debug-toolbar.component';
import { GridHelper } from '../grid-helper/grid-helper.component';
import debounce from 'lodash.debounce';

export const Page = withAppStore(props => {
  const { dispatch, state } = useContext(AppStore);
  useEffect(() => {
    const handleResize = (): void =>
      dispatch({
        type: AppActionType.deviceSize,
        payload: window.innerWidth
      });

    const debouncedResize =
      state.showActiveDevice !== null
        ? debounce(handleResize, 300)
        : handleResize();

    window.addEventListener('resize', debouncedResize);
    return (): void => {
      window.removeEventListener('resize', debouncedResize);
    };
  }, [dispatch, state.showActiveDevice]);

  return (
    <>
      <DebugToolbar />
      {state.showGridHelper && <GridHelper />}
      {props.children}
    </>
  );
});

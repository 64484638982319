import React, { FunctionComponent } from 'react';
import styles from './grid-helper.module.scss';
import { Container } from './../container/container.component';

export const GridHelper: FunctionComponent<{}> = () => {
  const array = [...Array(12)];

  return (
    <div className={styles.wrapper}>
      <Container>
        <div className={styles.gridHelper}>
          {array.map((e, i) => (
            <div key={i} className={styles.gridColumn}></div>
          ))}
          ;
        </div>
      </Container>
    </div>
  );
};
